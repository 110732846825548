@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

:root {
  --background: #97bf0f;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'Source Sans Pro', sans-serif;
}

body {
  height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
p {
  background-color: transparent;
}

button {
  cursor: pointer;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.column,
.row {
  display: flex;
}
