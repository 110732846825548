.layout {
  width: 100%;
  min-height: 100%;
  display: flex;
}

ul {
  list-style: none;
  margin-top: 4rem;
  padding: 0;
}

li {
  padding: 0.5em;
}

li:hover {
  color: #fefefe !important;
  background-color: gray;
}

.layout > nav {
  padding: 10px;
  padding-left: 0;
}

.desktop-nav {
  min-width: 15em;
  max-width: 15em;
  min-height: 100vh;
  background-color: white;
  border-right: 1px solid #ddd;
  position: relative;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgb(0 0 0 / 6%), 0 2px 12px rgb(0 0 0 / 7%), 0 5px 20px rgb(0 0 0 / 9%);
}

.desktop-nav > ul > li > a {
  font-size: 18px;
  font-weight: bold;
  color: black;
  padding: 8px;
  display: block;
  text-align: center;
}

.desktop-nav > ul > li:hover {
  background-color: #ddd;
  cursor: pointer;
}

.active {
  background-color: var(--background);
  color: white !important;
}

.mobile-nav {
  display: none !important;
}

.close {
  z-index: 1001;
  position: relative;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
}

.close > div {
  background-color: var(--background);
  margin: 0;
  position: absolute;
  left: 15px;
  top: 0;
  width: 2px;
  height: 30px;
  border-radius: 8px;
}

.hamburger {
  display: none;
}

@media only screen and (max-width: 768px) {
  .line {
    width: 30px;
    height: 2px;
    min-height: 1.5px;
    background: var(--background);
    border-radius: 8px;
    margin-bottom: 7px;
  }

  .desktop-nav {
    display: none !important;
  }

  .mobile-nav {
    display: flex !important;
    height: 60px;
    top: 20px;
    left: 10px;
    z-index: 100;
    position: absolute;
  }

  .hamburger {
    display: flex;
    width: 30px;
    margin-right: 0;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  .line1 {
    transform: rotate(45deg);
  }

  .line2 {
    transform: rotate(-45deg);
  }
}

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  border: none;
  justify-content: flex-start;
  align-items: center;
}

.mobile-nav > .overlay > ul {
  width: 100%;
  gap: 10px;
}

.mobile-nav > .overlay > ul > li > a {
  font-size: 18px;
  font-weight: bold;
  color: var(--background);
  display: block;
  text-align: center;
  width: 100%;
  padding: 10px 0;
}

@media screen and (min-width: 768px) {
  .root-layout > nav {
    padding: 10px 0 10px 10px;
    position: fixed;
    height: 100vh;
  }
}
